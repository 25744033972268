import { styled } from "styled-components";

interface Props {
    icon?: string;
    iconAlt?: string;
	heading?: string;
    body?: any;
}

export const WarningBar = ({heading, body, icon, iconAlt}: Props) => {
    return (
        <StyledWarningBanner>
            {icon && <img src={icon} alt={iconAlt} />}
            <StyledWarningText>
                <StyledWarningHeading>
                    {heading}
                </StyledWarningHeading>
                <StyledWarningBodyText>
                    {body}
                </StyledWarningBodyText>
            </StyledWarningText>
        </StyledWarningBanner>
    );
};

const StyledWarningBanner = styled.div`
	padding: 10px 40px;
	display: flex;
	gap: 14px;
	background: #FFCDD7;
	@media (max-width: 719px) {
		padding: 10px 24px;
	}
`;

const StyledWarningText = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const StyledWarningHeading = styled.p`
	font-weight: 700;
`;

const StyledWarningBodyText = styled.p`
    a {
        text-decoration: underline;
    }
`;

export default WarningBar;